<template>
    <div v-if="visible" class="fixed flex items-center justify-center h-full w-full z-30 inset-0 overflow-y-auto">

        <div @click="hide" class="absolute inset-0 bg-gray-600 opacity-75"></div>

        <div class="flex flex-col fade-in w-full mx-4 sm:mx-0 sm:w-3/5 md:w-2/3 lg:w-2/6 bg-white rounded-lg overflow-hidden shadow-xl z-20">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="flex flex-col">
                    <div class="text-center w-full">
                        <h3 class="text-gray-600 pl-4 text-left text-2xl font-medium">
                            Ваше имя
                        </h3>
                        <div class="mt-2 px-4 flex justify-center">
                            <input v-model="name" type="text" class="text-blue-800 w-full rounded border p-1 pl-2 font-bold border-gray-500 text-black text-2xl">
                        </div>
                    </div>
                    <div class="mt-6 text-center w-full">
                        <h3 class="text-gray-600 pl-4 text-left text-2xl font-medium">
                            Ваш номер телефона
                        </h3>
                        <div class="mt-2 flex justify-center w-full">
                            <div class="text-black text-blue-800 w-5/6 z-50 w-full px-4">
                                <TelephoneInput ref="phoneInput"
                                                :inputClass="'no-outline text-dark'"
                                                :autocomplete="'off'"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-5/6 mx-auto px-4 pt-1 pb-1 sm:pb-4 flex justify-center items-center">
                <input type="checkbox" id="i-agree-with-privacy" v-model="agree" />
                <p class="ml-3 text-xs text-center text-gray-600" for="i-agree-with-privacy">
                    Я согласен(а) на обработку персональных данных и ознакомлен(а) с
                    <router-link
                       to="/privacy"
                       target="_blank"
                       class="cursor-pointer"
                    >
                        политикой конфиденциальности
                    </router-link>
                </p>
            </div>
            <div class="mt-auto px-4 pt-5 pb-4 sm:p-6 sm:pb-6">
                <button @click="sendForm" :class="{disabled: !agree}" class="unselectable w-full text-2xl font-bold rounded-md border border-transparent px-4 py-2 bg-green-500 hover:bg-green-400 text-xl font-medium text-white focus:outline-none">
                    Позвоните мне!
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import TelephoneInput from "@/components/TelephoneInput";
import axios from 'axios'

const toastOptions = {
    position: "top-center",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true
}

export default {
    name: "CallMeDialog",
    props: ['country', 'city'],
    components: {
        TelephoneInput

    },
    data() {
        return {
            visible: false,
            name: '',
            agree: true
        }
    },
    methods: {
        show() {
            this.visible = true
        },
        hide() {
            this.visible = false
        },
        sendForm() {
            if (!this.$refs.phoneInput.isValid()) {
                this.$toast.error("Введите номер телефона", toastOptions);
                return
            }
            const body = {
                name: this.name,
                phone: this.$refs.phoneInput.getValue(false),
                country: this.country,
                city: this.city
            }
            axios.post('https://simdelivery.ru/call_me.php', {
                payload: window.btoa(unescape(encodeURIComponent(JSON.stringify(body))))
            })
            this.hide()
            this.$toast.success("Наш менеджер свяжется с Вами в ближайшее время!", {
                ...toastOptions,
                timeout: 4000
            });
        }
    }
}
</script>

<style scoped>

.disabled {
    pointer-events: none;
    transition: opacity;
    opacity: 0.4;
}

.fade-in {
    animation: fadeIn ease 0.4s;
    -webkit-animation: fadeIn ease 0.4s;
    -moz-animation: fadeIn ease 0.4s;
    -o-animation: fadeIn ease 0.4s;
    -ms-animation: fadeIn ease 0.4s;
}

@keyframes fadeIn {
    0% { transform: scale(0.5); }
    100% { transform: scale(1.0); }
}

@-moz-keyframes fadeIn {
    0% { transform: scale(0.5); }
    100% { transform: scale(1.0); }
}

@-webkit-keyframes fadeIn {
    0% { transform: scale(0.5); }
    100% { transform: scale(1.0); }
}

@-o-keyframes fadeIn {
    0% { transform: scale(0.5); }
    100% { transform: scale(1.0); }
}

@-ms-keyframes fadeIn {
    0% { transform: scale(0.5); }
    100% { transform: scale(1.0); }
}
</style>

<template>
  <div class="wrapper">
    <label
        v-if="label"
        for="address"
        :class="{ 'focused': isFocus || searchQuery }"
        class="label"
    >
      {{ label }}
    </label>
    <input
        type="text"
        id="address"
        v-model="searchQuery"
        :class="{'input-error': hasError && !isFocus}"
        @input="handleInputChange"
        @focus="handleInputFocus"
        @blur="handleInputBlur"
    />
    <span v-if="hasError && !isFocus" class="error-icon">
      <img :src="require('@/assets/exclamation.svg')" alt="alert">
    </span>
    <span v-if="hasError" class="error-text">
      {{ errorText }}
    </span>

    <ul v-if="isDropdownOpen" class="options-list">
      <div v-if="addresses.length > 0">
        <span class="options-desc">
          Выберите вариант или продолжите ввод
        </span>
        <li v-for="address in addresses" :key="address.value" @click="selectAddress(address)">
          <span class="option">
            {{ address.value }}
          </span>
        </li>
      </div>
      <div v-else class="options-desc">
        Неизвестный адрес
      </div>
    </ul>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Tele2DadataAddressSelect',
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    error: {
      type: String,
    },
  },
  computed: {
    errorText() {
      return this.error || 'Поле не должно быть пустым';
    }
  },
  data() {
    return {
      searchQuery: this.value || '',
      addresses: [],
      selectedAddress: null,
      searchTimeout: null,
      isFocus: false,
      isDropdownOpen: false,
      hasError: false,
    };
  },
  methods: {
    handleInputChange(event) {
      if (event.target.value.trim() === '') {
        this.hasError = true;
      } else {
        this.hasError = false;
      }
      this.searchAddress();
    },
    handleInputBlur() {
      this.isFocus = false;

      if (!this.searchQuery) {
        this.hasError = true;
      }
    },
    handleInputFocus() {
      this.isFocus = true;
      if (this.searchQuery) {
        this.isDropdownOpen = true;
      }
    },
    selectAddress(address) {
      this.selectedAddress = address;
      this.searchQuery = address.value;
      this.isDropdownOpen = false;
      this.isValid();
      this.$emit('address-selected', address);
    },
    async searchAddress() {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(async () => {
        try {
          const response = await axios.post(
              'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
              {
                query: this.searchQuery,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Token ' + process.env.VUE_APP_DADATA_TOKEN,
                },
              }
          );

          this.addresses = response.data.suggestions;
          this.isDropdownOpen = true;
        } catch (error) {
          console.error('Error fetching addresses:', error);
        }
      }, 300);
    },
    handleDocumentClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
    isValid() {
      if (!this.searchQuery) {
        this.hasError = true;
      } else {
        this.hasError = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick);
  },
}
</script>
<style scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
}

.wrapper input {
  width: 100%;
  height: 60px;
  border: 1px solid #b9bdc4;
  border-radius: 6px;
  background-color: #fff;
  padding: 32px 50px 11px 14px;
  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 90px;
  outline: none;
}

.wrapper input:hover {
  border-color: #1f2229
}

.wrapper input.input-error {
  border-color: #d72f4b;
}

.wrapper label {
  position: absolute;
  top: 20px;
  left: 14px;
  color: #65707b;
  cursor: text;
  transition: transform 0.3s ease-in-out;
}

.wrapper label.focused {
  top: 7px;
  transform: scale(0.8) translate(-12.5%, 0);
}

.error-text {
  display: block;
  padding: 3px 0 2px;
  overflow: initial;
  color: #d72f4b;
  margin: 7px 4px 0 16px;
  font-size: 14px;
  line-height: 1.28;
  z-index: 1;
  width: auto;
}

.error-icon {
  position: absolute;
  top: 18px;
  right: 12px;
  display: inline-block;
  width: 26px;
  height: 26px;
}

.options-list {
  padding: 8px 0;
  position: absolute;
  max-height: 250px;
  overflow: auto;
  top: 110%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 17px 40px 0 rgba(31, 34, 41, 0.07), 0 2px 8px 0 rgba(31, 34, 41, 0.04), 0 50px 30px -40px rgba(31, 34, 41, 0.02);
}

.options-list::-webkit-scrollbar {
  width: 12px;
}

.options-list::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 100px;
  background-color: #D3D9DF;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
  border-right: 3px solid white;
}

.options-list li {
  cursor: pointer;
  display: block;
  padding-left: 4px;
  padding-right: 12px;
}

.option {
  display: block;
  padding: 9px 16px;
  border-radius: 8px;
}

.option:hover {
  background-color: #1F2229;
  color: #fff;
}

.label {
  position: absolute;
  top: 20px;
  left: 14px;
  color: #65707b;
  cursor: text;
  transition: transform 0.3s ease-in-out;
}

.label.focused {
  top: 7px;
  transform: scale(0.8) translate(-12.5%, 0);
}

.options-desc {
  display: block;
  font-size: 14px;
  color: #65707b;
  padding: 9px 28px 9px 20px;
}

</style>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-inner">
        <div>
          © 2024 ИП Зарипов Равиль Рифкатович (ИНН: 161400612650, ОГРНИП: 317169000131081).
        </div>
        <div class="footer-privacy">
          Продолжая использовать наш сайт, вы даете согласие на обработку файлов
          Cookies и других пользовательских данных, в соответствии с
          <router-link to="/privacy" target="_blank">политикой конфиденциальности</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Tele2Footer'
}
</script>
<style scoped>

.footer {
  background: #1f2229;
  color: #8f9399;
  font-size: 13px;
  line-height: 18px;
}

.footer-inner {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.footer-privacy a {
  color: #8f9399;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.footer-privacy a:hover {
  color: #fff;
}

</style>

<template>
    
    <router-view></router-view>
    
</template>

<script>

export default {
    name: 'App',
    components: {
    },
}
</script>

<style>
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>

<template>
  <div>
    <div v-if="checkStatus === 1">
      <img :src="require('@/assets/circles.gif')" alt="circles" />
      Еще немного – пожалуйста, подождите
    </div>
    <div
        v-else-if="checkStatus === 3"
        class="operation-result"
    >
      <div>
        <Tele2CardHeader
            title="Не удалось проверить адрес"
            :subtitle="checkDescription || 'Произошла непредвиденная ошибка'"
        />
        <Tele2Button @click="checkStatus = 0">
          Попробовать еще раз
        </Tele2Button>
      </div>
      <img :src="require('@/assets/tele2-error-image.svg')" alt="error-image">
    </div>
    <div v-else>
      <Tele2CardHeader
          title="Проверьте возможность подключения по адресу"
          subtitle="Укажите адрес, где нужен домашний интернет"
      />
      <div class="form">
        <div class="fields">
          <div class="inputs-container">
            <div class="input-address">
              <Tele2DadataAddressSelect
                  :value="address ? address.value : ''"
                  ref="addressInput"
                  label="Город, улица, дом, квартира"
                  error="Укажите адрес"
                  @address-selected="handleAddressOptionSelected"
              />
            </div>
            <div class="inputs-entrance-and-floor">
              <Tele2Input
                  v-model="entrance"
                  ref="entranceInput"
                  label="Подъезд"
                  type="number"
                  error="Укажите подъезд"
              />
              <Tele2Input
                  v-model="floor"
                  ref="floorInput"
                  label="Этаж"
                  type="number"
                  error="Укажите этаж"
              />
            </div>
          </div>
        </div>
        <div class="check">
          <Tele2Button @click="checkInternet">
            Проверить
          </Tele2Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tele2DadataAddressSelect from "@/components/tele2/Tele2DadataAddressSelect.vue";
import Tele2Input from "@/components/tele2/Tele2Input.vue";
import Tele2Button from "@/components/tele2/Tele2Button.vue";
import axios from "axios";
import Tele2CardHeader from "@/components/tele2/Tele2CardHeader.vue";

export default {
  name: 'Tele2InternetConnectionCheckForm',
  components: {
    Tele2CardHeader,
    Tele2Button,
    Tele2Input,
    Tele2DadataAddressSelect
  },
  data() {
    return {
      address: null,
      entrance: null,
      floor: null,
      checkDescription: '',
      checkStatus: 0, // 0 - initial, 1 - loading, 2 - success, 3 - error
    }
  },
  methods: {
    handleAddressOptionSelected(option) {
      this.address = option;
      this.$emit('change-address', option);
    },
    async checkInternet() {
      if (!this.address || !this.entrance || !this.floor) {
        this.$refs.addressInput.isValid();
        this.$refs.entranceInput.isValid();
        this.$refs.floorInput.isValid();

        return
      }

      const body = {
        address: {
          postalCode: this.address.data.postal_code,
          region: {
            fiasCode: this.address.data.region_fias_id,
            type: this.address.data.region_type,
            name: this.address.data.region
          },
          district: {
            fiasCode: this.address.data.city_district_fias_id,
            type: this.address.data.city_district_type,
            name: this.address.data.city_district
          },
          city: {
            fiasCode: this.address.data.city_fias_id,
            type: this.address.data.city_type,
            name: this.address.data.city
          },
          locality: {
            fiasCode: this.address.data.region_fias_id,
            type: this.address.data.region_type,
            name: this.address.data.region
          },
          street: {
            fiasCode: this.address.data.street_fias_id,
            type: this.address.data.street_type,
            name: this.address.data.street
          },
          house: {
            fiasCode: this.address.data.house_fias_id,
            type: this.address.data.house_type,
            name: this.address.data.house
          },
          fullAddress: this.address.value,
          apartment: this.address.data.flat,
          entrance: this.entrance,
          floor: this.floor,
        },
        client: {
          name: "имя не задано",
          phone: "79111111111",
        }
      }

      this.checkStatus = 1;
      try {
        const { data } = await axios.post(
            'https://sim-delivery.ru/r/tele2BroadbandAccessAddressCheck',
            body,
        )

        if (data.meta.status === 'OK') {
          this.checkStatus = 2;
          this.checkDescription = data.meta.description;
          this.$emit('close');
        }
      } catch (e) {
        this.checkStatus = 3;
        this.checkDescription = e.response.data.meta.description;
        console.error(e);
      }
    }
  },
  watch: {
    entrance() {
      this.$emit('change-entrance', this.entrance);
    },
    floor() {
      this.$emit('change-floor', this.floor);
    }
  }
}
</script>
<style scoped>

.form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
}

.fields {
  flex-grow: 1;
}

.check {
  display: flex;
  align-items: center;
}

.inputs-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.input-address {
  flex-grow: 1;
}

.inputs-entrance-and-floor {
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-basis: 32%;
}

.operation-result {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}

.operation-result img {
  width: 196px;
  height: 196px;
}

@media (max-width: 767px) {
  .form {
    flex-direction: column;
    gap: 12px;
  }

  .fields {
    width: 100%;
  }

  .check {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .inputs-container {
    flex-direction: column;
  }

  .operation-result {
    display: block;
  }

  .operation-result img {
    margin-top: 24px;
  }
}

</style>

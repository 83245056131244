
<template>
    
    <AzatLinksPage v-if="name === 'azat'" />
    <SimdelSoftLinksPage v-else-if="name === 'soft'" />
    <SimDeliveryLinksPage v-else-if="name === 'links'" />

</template>

<script>

import Topbar from "@/components/Topbar";
import AzatLinksPage from "@/pages/AzatLinksPage";
import SimDeliveryLinksPage from "@/pages/SimDeliveryLinksPage.vue";
import SimdelSoftLinksPage from "@/pages/SimdelSoftLinksPage.vue";

export default {
    components: {
        SimDeliveryLinksPage,
        SimdelSoftLinksPage,
        AzatLinksPage,
        Topbar
    },
    props: ['name'],
    methods: {
    },
    created() {
    }
}
</script>

<style>

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#main-container {
    margin: 0 auto;
    max-width: 600px;
}

</style>

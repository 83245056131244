<template>
    <div class="w-full md:w-2/5 p-6 flex flex-col flex-grow flex-shrink">
        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
            <a href="#" class="flex flex-wrap no-underline hover:no-underline">
                <div class="w-full font-bold text-2xl text-gray-700 text-1xl bg-gray-300 p-5 px-4">{{ title }}</div>
                <p class="text-gray-800 text-base mt-2 px-6 mb-5" style="font-size: 1.4em;">
                    {{ description }}
                </p>
            </a>
        </div>
        <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div class="flex items-center justify-start">
                <button @click="$emit('call-me')" class="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">Подробнее</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServiceBlock",
    props: ['title', 'description']
}
</script>

<style scoped>

</style>

<template>
    <section class="bg-gray-100 py-8">
        <div class="container mx-auto flex flex-wrap pt-4 pb-12">

            <h1 class="w-full my-2 text-3xl md:text-5xl font-bold leading-tight text-center text-gray-800">Услуги</h1>
            <div class="w-full mb-4">
                <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>

            <ServiceBlock @call-me="callMe"
                          :title="'Экспресс доставка SIM-карт'"
                          :description="'Вы оператор мобильной связи - вам нужна качественная доставка SIM-карт. Необходимо подключить нового абонента или составить заявление на MNP? - Мы знаем, что делать!'"
            />

            <ServiceBlock @call-me="callMe"
                          :title="'Отправка заказов из интернет магазинов'"
                          :description="'У вас есть магазин, но нет доставки? Не проблема! Доставляем товары быстро, качественно, конфеденциально! Ежедневно, с 9:00 до 21:00 без выходных и праздников. Оставьте заявку, и мы перезвоним вам в течение минуты!'"
            />

            <ServiceBlock @call-me="callMe"
                          :title="'Работа с персональными данными'"
                          :description="'У вас нет своего штата операторов? Арендуйте наших опытных операторов, стаж работы от 2 лет в сфере телекоммуникации и работы с персональными данными.'"
            />

            <ServiceBlock @call-me="callMe"
                          :title="'SMS Информирование заказчиков'"
                          :description="'Готовое решение по SMS информированию заказчиков - статус доставки, назначенный курьер, срок доставки, контакты для связи, обратная связь от клиентов.'"
            />

        </div>
    </section>
</template>

<script>
import ServiceBlock from "@/components/ServiceBlock";
export default {
    name: "Services",
    components: {ServiceBlock},
    methods: {
        callMe() {
            this.$emit('call-me')
        }
    }
}
</script>

<style scoped>

</style>

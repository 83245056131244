<template>
  <div class="page">
    <Tele2Header />
    <div class="content">
      <div class="container">
        <div class="card">
          <Tele2ApplicationForm
              v-if="hasInternetConnectionBeenChecked"
              :address="formData.address"
              :entrance="formData.entrance"
              :floor="formData.floor"
          />
          <Tele2InternetConnectionCheckForm
              v-else
              @close="hasInternetConnectionBeenChecked = true"
              @change-address="changeAddress"
              @change-entrance="changeEntrance"
              @change-floor="changeFloor"
          />
        </div>
      </div>
    </div>
    <Tele2Footer />
  </div>
</template>

<script>
import Vue from "vue";
import '@/assets/css/tele2.css';
import Tele2Header from "@/components/tele2/Tele2Header.vue";
import Tele2ApplicationForm from "@/components/tele2/Tele2ApplicationForm.vue";
import Tele2Footer from "@/components/tele2/Tele2Footer.vue";
import Tele2InternetConnectionCheckForm from "@/components/tele2/Tele2InternetConnectionCheckForm.vue";

export default {
  components: {
    Tele2InternetConnectionCheckForm,
    Tele2Footer,
    Tele2ApplicationForm,
    Tele2Header
  },
  data() {
    return {
      formData: {
        address: null,
        entrance: null,
        floor: null,
      },
      hasInternetConnectionBeenChecked: false
    };
  },
  methods: {
    changeAddress(address) {
      this.formData.address = address;
    },
    changeEntrance(entrance) {
      this.formData.entrance = entrance;
    },
    changeFloor(floor) {
      this.formData.floor = floor;
    }
  },
  created() {
    Vue.nextTick(() => {
      document.title = 'Домашний интернет Tele2';
    });
  }
}
</script>
<style scoped>
@font-face {
  font-family: 'tele2_displayserif-bold';
  src: local('tele2_displayserif-bold'),
      url('/fonts/Tele2DisplaySerif-Bold-short_W/Tele2DisplaySerif-Bold-short__W.eot');
  src: local('tele2_displayserif-bold'),
      url('/fonts/Tele2DisplaySerif-Bold-short_W/Tele2DisplaySerif-Bold-short__W.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Tele2DisplaySerif-Bold-short_W/Tele2DisplaySerif-Bold-short__W.woff') format('woff'),
      url('/fonts/Tele2DisplaySerif-Bold-short_W/Tele2DisplaySerif-Bold-short__W.ttf') format('truetype'),
      url('/fonts/Tele2DisplaySerif-Bold-short_W/Tele2DisplaySerif-Bold-short__W.svg#Tele2DisplaySerifWebSHORT-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'tele2_displayserif-regular';
  src: local('tele2_displayserif-regular'),
      url('/fonts/Tele2DisplaySerif-Regular-short_W/Tele2DisplaySerif-Regular-short__W.eot');
  src: local('tele2_displayserif-regular'),
      url('/fonts/Tele2DisplaySerif-Regular-short_W/Tele2DisplaySerif-Regular-short__W.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Tele2DisplaySerif-Regular-short_W/Tele2DisplaySerif-Regular-short__W.woff') format('woff'),
      url('/fonts/Tele2DisplaySerif-Regular-short_W/Tele2DisplaySerif-Regular-short__W.ttf') format('truetype'),
      url('/fonts/Tele2DisplaySerif-Regular-short_W/Tele2DisplaySerif-Regular-short__W.svg#Tele2DisplaySerifWebSHORT-Reg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'tele2_textsans_bold';
  src: local('tele2_textsans_bold'),
      url('/fonts/Tele2TextSans-Bold-short_W/Tele2TextSans-Bold-short__W.eot');
  src: local('tele2_textsans_bold'),
      url('/fonts/Tele2TextSans-Bold-short_W/Tele2TextSans-Bold-short__W.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Tele2TextSans-Bold-short_W/Tele2TextSans-Bold-short__W.woff') format('woff'),
      url('/fonts/Tele2TextSans-Bold-short_W/Tele2TextSans-Bold-short__W.ttf') format('truetype'),
      url('/fonts/Tele2TextSans-Bold-short_W/Tele2TextSans-Bold-short__W.svg#Tele2TextSansWebSHORT-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'tele2_textsans_regular';
  src: local('tele2_textsans_regular'),
      url('/fonts/Tele2TextSans-Regular-short_W/Tele2TextSans-Regular-short__W.eot');
  src: local('tele2_textsans_regular'),
      url('/fonts/Tele2TextSans-Regular-short_W/Tele2TextSans-Regular-short__W.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Tele2TextSans-Regular-short_W/Tele2TextSans-Regular-short__W.woff') format('woff'),
      url('/fonts/Tele2TextSans-Regular-short_W/Tele2TextSans-Regular-short__W.ttf') format('truetype'),
      url('/fonts/Tele2TextSans-Regular-short_W/Tele2TextSans-Regular-short__W.svg#Tele2TextSansWebSHORT-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

.page {
  --base-font-family: tele2_textsans_regular,sans-serif;
  --bold-font-family: tele2_displayserif-bold,serif;
  --regular-title-font-family: tele2_displayserif-regular,serif;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  color: #1f2229;
  background: #fff;
  font: 16px/1.375 var(--base-font-family);
  min-width: 320px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none
}

.content {
  padding: 54px 0;
  flex: 1 0 auto;
}

.card {
  padding: 40px;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.08);
  border-radius: 12px;
}

@media (max-width: 767px) {
  .card {
    padding: 32px 18px 32px;
    border-radius: 0;
  }
}
</style>

<template>
    <div class="inline flex flex-row w-full rounded overflow-hidden border p-0">
        <div class="bg-gray-600 text-white font-light border-r text-2xl font-medium rounded-l p-2 pr-3 m-0">
            <span class="">+7</span>
        </div>
        <input class="bg-gray-200 text-2xl font-bold rounded-r pl-3 w-full m-0"
               type="tel"
               v-model="phone"
               @copy.prevent="onCopy"
               name="phone"
               id="phone"
               placeholder="(___) ___-____"
               autocomplete="tel"
               maxlength="14"
               :class="inputClass"
               :autocomplete="autocomplete"
               v-phone
               pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}" required />
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "TelephoneInput",
    props: ['inputClass', 'autocomplete'],
    data: function () {
        return {
            phone: ''
        }
    },
    methods: {
        onCopy: function () {
            if (! this.isValid()) return
            this.getValue().copyToClipboard()
        },
        getValue: function(leadingEight = true) {
            return (
                (leadingEight ? '8' : '')
                + this.phone.replace(/\D/g, '')
            )
        },
        setValue: function(phone) {
            this.phone = phone.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "($1) $2-$3");
        },
        isValid: function () {
            return this.phone.length === 14
        }
    }
}

Vue.directive('phone', {
    bind(el) {
        el.oninput = function(e) {
            if (!e.isTrusted) {
                return;
            }
            let x = this.value.replace(/\D/g, '')
            x = x.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            el.dispatchEvent(new Event('input'));
        }
    }
});
</script>

<style scoped>
</style>

<template>
  <header class="header">
    <div class="container">
      <div class="wrapper">
        <div class="logo">
          <img :src="require('@/assets/Tele2-logo-white.png')" alt="logo">
        </div>
        <h3>Партнер SimDelivery</h3>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'Tele2Header',
}
</script>
<style scoped>
.header {
  height: 45px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  color: #fff;
  background: #1f2229;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  margin-right: 27px;
}

@media all and (min-width: 840px) {
  .logo {
    max-width:59px
  }
}

@media all and (max-width: 839px) {
  .logo {
    max-width:48px
  }
}
</style>

<template>
  <button class="btn" @click="handleClick">
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'Tele2Button',
  methods: {
    handleClick() {
      this.$emit("click")
    },
  },
}
</script>
<style scoped>
button {
  font: 100% var(--base-font-family);
  color: #1f2229;
}

.btn {
  display: inline-block;
  vertical-align: middle;
  padding: 17px 43px;
  border-radius: 30px;
  font-family: var(--base-font-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  min-width: 125px;
  border: 2px #1f2229 solid;
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition-property: color, background-color, border-color;
  outline: none;
  color: white;
  background: #1f2229;
}

.btn:hover,:focus {
  color:white;
  background: #626469;
  border-color: #626469;
  outline: 0
}

@media (max-width: 767px) {
  .btn {
    padding: 11px 33px;
    border-radius: 24px;
  }
}

</style>

<template>
  <div class="select">
    <div class="selected-option" :class="{ 'select-error': hasError }" @click="isDropdownOpen = true">
      <span class="label" :class="{ 'focused': selectedOption.label }">{{label}}</span>
      <button v-if="!hasError" class="button" :class="{ 'active': isDropdownOpen }">
        <img :src="require('@/assets/icon-dropdown.svg')" alt="dropdown">
      </button>
      <span v-if="hasError && !isFocus" class="error-icon">
        <img :src="require('@/assets/exclamation.svg')" alt="alert">
      </span>
      {{ selectedOption.label }}
    </div>
    <ul v-if="isDropdownOpen" class="options-list">
      <li v-if="options.length === 0">
        <span class="option disabled">
          -- Выберите значение --
        </span>
      </li>
      <li
          v-for="(option, index) in options"
          :key="index" @click="selectOption(option)"
      >
        <span class="option" :class="{ 'active': selectedOption.value === option.value }">
          {{ option.label }}
        </span>
      </li>
    </ul>
    <span v-if="hasError" class="error-text">
      {{ errorText }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'Tele2Select',
  props: {
    options: Array,
    selected: Object,
    label: String,
    error: String,
  },
  computed: {
    errorText() {
      return this.error || 'Выберите значение';
    }
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedOption: this.selected || {},
      isFocus: false,
      hasError: false,
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.isDropdownOpen = false;
      this.$emit('option-selected', option);
      this.isValid();
    },
    handleDocumentClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
    isValid() {
      if (!this.selectedOption.value) {
        this.hasError = true;
      }else {
        this.hasError = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick);
  },
  watch: {
    selected(newSelected) {
      this.selectedOption = newSelected;
    },
  },
}
</script>
<style scoped>
.select {
  width: 100%;
  min-width: 90px;
  position: relative;
  outline: none;
  cursor: pointer;
}

.selected-option {
  height: 60px;
  will-change: border-color;
  line-height: 1;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #b9bdc4;
  border-radius: 6px;
  background-color: #fff;
  padding: 32px 50px 11px 14px;
  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  display: block;
  min-width: 90px;
}

.selected-option:hover {
  border-color: #1f2229;
}

.selected-option.select-error {
  border-color: #d72f4b;
}

.options-list {
  padding: 8px 0;
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 17px 40px 0 rgba(31, 34, 41, 0.07), 0 2px 8px 0 rgba(31, 34, 41, 0.04), 0 50px 30px -40px rgba(31, 34, 41, 0.02);
}

.options-list li {
  display: block;
  padding-left: 4px;
  padding-right: 12px;
}

.option {
  display: block;
  padding: 9px 16px;
  border-radius: 8px;
}

.option.active {
  background-color: #EDF0F2;
}

.option:hover {
  background-color: #1F2229;
  color: #fff;
}

.option.disabled {
  color: #65707b;
  background-color: transparent;
  cursor: auto;
}

.label {
  position: absolute;
  top: 20px;
  left: 14px;
  color: #65707b;
  cursor: text;
  transition: transform 0.3s ease-in-out;
}

.label.focused {
  top: 7px;
  transform: scale(0.8) translate(-12.5%, 0);
}

.button {
  position: absolute;
  top: 18px;
  right: 16px;
  width: 24px;
  height: 24px;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.button.active {
  transform: rotate(180deg);
}

.error-text {
  display: block;
  padding: 3px 0 2px;
  overflow: initial;
  color: #d72f4b;
  margin: 7px 4px 0 16px;
  font-size: 14px;
  line-height: 1.28;
  z-index: 1;
  width: auto;
}

.error-icon {
  position: absolute;
  top: 18px;
  right: 12px;
  display: inline-block;
  width: 26px;
  height: 26px;
}

</style>

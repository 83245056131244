<template>
    <section class="bg-white border-b-2 py-8">
        <div class="container max-w-5xl mx-auto m-8">
            <h1 class="w-full my-2 text-4xl md:text-5xl font-bold leading-tight text-center text-gray-800">Преимущества</h1>
            <div class="w-full mb-4">
                <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>

            <div class="sm:mt-8 flex flex-wrap flex-col-reverse sm:flex-row">
                <div class="hidden sm:block p-10 w-1/2 mx-auto lg:w-1/3">
                    <img :src="require('@/assets/project_completed.svg')" draggable="false" alt="">
                </div>
                <div class="pl-10 w-full sm:w-1/2 p-6">
                    <div class="align-middle">
                        <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">Опыт</h3>
                        <p class="text-gray-600 text-xl">У нас есть опыт доставки банковских и телекоммуникационных продуктов</p>
                    </div>
                </div>
            </div>

            <div class="sm:mt-8 flex flex-wrap flex-col-reverse sm:flex-row">
                <div class="pl-10 md:pl-24 w-full sm:w-1/2 p-6">
                    <div class="align-middle">
                        <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">Личный кабинет</h3>
                        <p class="text-gray-600 text-xl">Все актуальные данные доступны через удобный веб-интерфейс: просмотр состояния доставки, добавление новых заказов</p>
                    </div>
                </div>
                <div class="hidden sm:block w-1/2 mx-auto lg:w-1/3">
                    <img :src="require('@/assets/dashboard.svg')" draggable="false" alt="">
                </div>
            </div>

            <div class="sm:mt-8 flex flex-wrap flex-col-reverse sm:flex-row">
                <div class="hidden sm:block p-10 w-1/2 mx-auto lg:w-1/3">
                    <img :src="require('@/assets/server.svg')" draggable="false" alt="">
                </div>
                <div class="pl-10 w-full sm:w-1/2 p-6">
                    <div class="align-middle">
                        <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">Интеграция</h3>
                        <p class="text-gray-600 text-xl">
                            У нас открытый API, можем интегрироваться с вашей системой. Наши IT-специалисты помогут быстро и без лишних затрат наладить отправку заказов в курьерскую службу.
                            <!--<a href="">Документация</a>. -->
                        </p>
                    </div>
                </div>
            </div>

            <div class="sm:mt-8 flex flex-wrap flex-col-reverse sm:flex-row">
                <div class="pl-10 md:pl-24 w-full sm:w-1/2 p-6">
                    <div class="align-middle">
                        <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">Большая география</h3>
                        <p class="text-gray-600 text-xl">Мы работаем во всех крупных городах России</p>
                    </div>
                </div>
                <div class="hidden sm:block w-1/2 mx-auto lg:w-1/3">
                    <WorldMapSvg />
                </div>
            </div>

            <div class="sm:mt-8 flex flex-wrap flex-col-reverse sm:flex-row">
                <div class="hidden sm:block w-1/2 mx-auto lg:w-1/3">
                    <img :src="require('@/assets/credit_card_payment.svg')" draggable="false" alt="">
                </div>
                <div class="pl-10 w-full sm:w-1/2 p-6">
                    <div class="align-middle">
                        <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">Касса</h3>
                        <p class="text-gray-600 text-xl">Курьер примет наличную или безналичную оплату, выдаст чек. Работа по 54-ФЗ, возможность установки вашей кассы у нас</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import WorldMapSvg from "@/components/WorldMapSvg";

export default {
    name: "Advantages",
    components: {
        WorldMapSvg
    }
}
</script>

<style scoped>

</style>

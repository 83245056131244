<template>
  <div>
    <h2 class="title" v-if="title">
      {{ title }}
    </h2>
    <p class="subtitle" v-if="subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'Tele2CardHeader',
  props: ["title", "subtitle"],
}
</script>
<style scoped>
.title {
  font-size: 28px;
  line-height: 37px;
  font-family: var(--bold-font-family);
  font-weight: 400;
  margin: 0 0 12px;
  color: #1f2229;
}

.subtitle {
  color: #65707b;
  margin: 0 0 24px;
}

@media (max-width: 767px) {
  .title {
    font-size: 24px;
    line-height: 33px;
  }
}
</style>

<template>
  <div>
    <div v-if="status === 1">
      <img :src="require('@/assets/circles.gif')" alt="circles">
      Еще немного – пожалуйста, подождите
    </div>
    <div
        v-else-if="status === 2"
        class="operation-result"
    >
      <div>
        <Tele2CardHeader
            title="Подключение домашнего интернета"
            subtitle="Мы свяжемся с вами в ближайшее время, чтобы обсудить детали"
        />
      </div>
      <img :src="require('@/assets/tele2-succes-image.png')" alt="succes-image">
    </div>
    <div
        v-else-if="status === 3"
        class="operation-result"
    >
      <div>
        <Tele2CardHeader
            title="Не получилось отправить заявку"
        />
        <Tele2Button @click="status = 0" class="button-retry">
          Попробовать еще раз
        </Tele2Button>
      </div>
      <img :src="require('@/assets/tele2-error-image.svg')" alt="error-image">
    </div>
    <div v-else>
      <Tele2CardHeader
        title="Отлично! По вашему адресу можно подключить домашний интернет"
        subtitle="Оставьте свои контакты, и мы с вами свяжемся."
      />
      <div class="form">
        <div class="form-contacts">
          <div class="form-contacts-col">
            <Tele2Input
                v-model="formData.fullName"
                ref="nameInput"
                label="Фамилия и имя"
                error="Укажите фамилию и имя"
            />
          </div>
          <div class="form-contacts-col">
            <Tele2TelephoneInput
                v-model="formData.phoneNumber"
                ref="phoneInput"
                label="Телефон"
                error="Укажите номер телефона"
            />
          </div>
        </div>
        <div class="form-tariff">
          <Tele2Select
              :selected="formData.tariff"
              :options="formattedTariffs"
              ref="tariffSelect"
              label="Тариф"
              error="Выберите тариф"
              @option-selected="handleTariffOptionSelected"
          />
        </div>
        <div class="form-buttons">
          <div class="form-submit">
            <Tele2Button @click="submit">
              Заказать обратный звонок
            </Tele2Button>
          </div>
          <div class="form-agreement">
            Заказывая обратный звонок, вы принимаете
            <a href="https://s3.tele2.ru/ds-site/docs/home-internet/home-internet.pdf" target="_blank">
              правила акции
            </a>
            и даете согласие на обработку персональных данных.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tele2Input from "@/components/tele2/Tele2Input.vue";
import Tele2Button from "@/components/tele2/Tele2Button.vue";
import Tele2Select from "@/components/tele2/Tele2Select.vue";
import Tele2TelephoneInput from "@/components/tele2/Tele2TelephoneInput.vue";
import Tele2DadataAddressSelect from "@/components/tele2/Tele2DadataAddressSelect.vue";
import axios from "axios";
import Tele2CardHeader from "@/components/tele2/Tele2CardHeader.vue";

export default {
  name: 'Tele2ApplicationForm',
  components: {
    Tele2CardHeader,
    Tele2DadataAddressSelect,
    Tele2TelephoneInput,
    Tele2Select,
    Tele2Button,
    Tele2Input
  },
  props: {
    address: {
      type: Object,
    },
    entrance: {
      type: [String, Number],
    },
    floor: {
      type: [String, Number],
    }
  },
  data() {
    return {
      sellerId: 1,
      formData: {
        fullName: '',
        phoneNumber: '',
        address: this.address,
        entrance: this.entrance || '',
        floor: this.floor || '',
        tariff: null,
      },
      tariffs: [],
      status: 0, // 0 - initial, 1 - loading, 2 - success, 3 - error
    }
  },
  computed: {
    fullAddress() {
      return this.formData.address.value + ', подъезд ' + this.formData.entrance + ', этаж ' + this.formData.floor
    },
    formattedTariffs() {
      return this.tariffs.map(tariff => {
        return {
          label: tariff.name,
          value: tariff.id
        }
      })
    }
  },
  methods: {
    handleTariffOptionSelected(option) {
      this.formData.tariff = option;
    },
    handleAddressOptionSelected(option) {
      this.formData.address = option;
    },
    async fetchTariffs() {
      try {
        const response = await axios.post(
          'https://sim-delivery.ru/r/getHomeInternetAdditionalSaleV2Tariffs',
            {
              sellerId: this.sellerId,
            },
            {
              params: {
                token: process.env.VUE_APP_TELE2_HOME_INTERNET_TOKEN
              }
            }
        )
        this.tariffs = response.data;
      } catch (error) {
        if (error.response) {
          const errors = error.response.data?.data?.errors;

          const searchTerm = /Продавец sellerId=\d+ не найден/;
          const found = errors.some(error => searchTerm.test(error));

          if (found) {
            this.sellerId = 1;
            await this.fetchTariffs();
          }
        }
        console.error(error);
      }
    },
    async submit() {
      if (!this.formData.fullName || !this.formData.phoneNumber) {
        this.$refs.nameInput.isValid();
        this.$refs.phoneInput.isValid();
        this.$refs.tariffSelect.isValid();

        return
      }

      const {fullName, tariff} = this.formData;
      const phoneNumber = this.formData.phoneNumber.replace(/\D/g, '');

      const body = {
        sellerId: this.sellerId,
        formData: {
          tariffId: tariff?.value || null,
          fullName,
          phoneNumber,
          fullAddress: this.fullAddress,
          city: this.formData.address.data.city_with_type,
          street: this.formData.address.data.street_with_type,
          house: this.formData.address.data.house,
          latitude: +this.formData.address.data.geo_lat,
          longitude: +this.formData.address.data.geo_lon
        }
      }

      this.status = 1;
      try {
        await axios.post(
            'https://sim-delivery.ru/r/createHomeInternetAdditionalSaleV2',
            body,
            {
              params: {
                token: process.env.VUE_APP_TELE2_HOME_INTERNET_TOKEN
              }
            }
        )
        this.status = 2;
      } catch (error) {
        this.status = 3;
        if (error.response) {
          const errors = error.response.data?.data?.errors;

          const searchTerm = /Продавец sellerId=\d+ не найден/;
          const found = errors.some(error => searchTerm.test(error));

          if (found) {
            this.sellerId = 1;
            await this.submit();
          }
        }
        console.error(error);
      }
    }
  },
  created() {
    const sid = this.$route.query.sid;
    if (sid) {
      this.sellerId = +sid;
    }

    this.fetchTariffs();
  }
}
</script>
<style scoped>

.form-contacts {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 12px;
}

.form-contacts-col {
  flex-basis: 50%;
}

.form-buttons {
  display: flex;
  margin-top: 30px;
  align-items: center;
}

.form-submit {
  order: 1;
}

.form-agreement {
  max-width: 400px;
  width: 100%;
  order: 4;
  margin-left: 18px;
  font-size: 13px;
  line-height: 18px;
  color: #8f9399;
}

.form-agreement a {
  text-decoration: underline;
  color: #8f9399;
  font-size: 13px;
  line-height: 18px;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.form-agreement a:hover {
  color: #1f2229;
}

.button-retry {
  margin: 32px 0 0;
}

.operation-result {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}

.operation-result img {
  width: 196px;
  height: 196px;
}

@media (max-width: 767px) {
  .form-contacts {
    flex-direction: column;
  }

  .form-buttons {
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }

  .form-agreement {
    order: 2;
    margin-left: 0;
    margin-top: 18px;
  }

  .operation-result {
    display: block;
  }
}
</style>

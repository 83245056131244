<template>
    <footer class="bg-white">
        <div class="container mx-auto  px-8">

            <div class="w-full flex flex-col md:flex-row py-6">

                <div class="flex-1 mb-6">

                    <router-link to="/" class="text-blue-500 no-underline hover:no-underline font-bold text-2xl"  href="#">
                        SimDelivery
                    </router-link>
                </div>

                <div class="flex-1">
                    <p class="uppercase text-gray-500 md:mb-6">в соц. сетях</p>
                    <ul class="list-reset mb-6">
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <font-awesome-icon :icon="['fab', 'vk']" class="fa-lg mr-2 text-black" style="width: 25px;" />
                            <a target="_blank" href="https://vk.com/simdelivery" class="no-underline hover:underline text-gray-800 hover:text-orange-500">ВКонтакте</a>
                        </li>
                        <li v-if="false" class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <font-awesome-icon :icon="['fab', 'instagram']" class="fa-lg mr-2 text-black" style="width: 25px;" />
                            <a target="_blank" href="https://www.instagram.com/simdelivery" class="no-underline hover:underline text-gray-800 hover:text-orange-500">Instagram</a>
                        </li>
                    </ul>
                </div>
                <div class="flex-1">
                    <p class="uppercase text-gray-500 md:mb-6">компания</p>
                    <ul class="list-reset mb-6">
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#" class="no-underline hover:underline text-gray-800 hover:text-orange-500">О нас</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#" class="no-underline hover:underline text-gray-800 hover:text-orange-500">Реквизиты</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <a href="#" class="no-underline hover:underline text-gray-800 hover:text-orange-500">Вакансии</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                            <router-link to="/privacy" class="no-underline hover:underline text-gray-800 hover:text-orange-500">
                              Политика конфидиацильности
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div class="flex-1">
                    <p class="uppercase text-gray-500 md:mb-6">наши контакты</p>
                    <ul class="mb-6 flex flex-col">
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0 whitespace-no-wrap">
                            <font-awesome-icon icon="phone-alt" class="fa-lg mr-2 text-black" />
                            <a href="tel:+78125079936" class="m-0 p-0 no-underline hover:underline text-gray-800 hover:text-orange-500">+7 (812) 507-99-36</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0 whitespace-no-wrap">
                            <font-awesome-icon icon="envelope" class="fa-lg mr-2 text-black" />
                            <a href="mailto:contact@simdelivery.ru" class="m-0 p-0 no-underline hover:underline text-gray-800 hover:text-orange-500">contact@simdelivery.ru</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0 whitespace-no-wrap">
                            <font-awesome-icon :icon="['fab', 'telegram']" class="fa-lg mr-2 text-black" />
                            <a target="_blank" href="https://t.me/sim_delivery" class="m-0 p-0 no-underline hover:underline text-gray-800 hover:text-orange-500">Перейти в чат</a>
                        </li>
                        <li class="mt-2 inline-block mr-2 md:block md:mr-0 whitespace-no-wrap">
                            <font-awesome-icon :icon="'clock'" class="fa-lg mr-2 text-black" />
                            <span class="m-0 p-0 text-gray-800">Ежедневно с 8:00 до 23:00</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>

</style>

<template>
  <div class="input-wrapper">
    <input
        type="tel"
        v-model="phoneNumber"
        maxlength="16"
        :id="inputId"
        :class="{'input-error': hasError && !isFocus}"
        @input="handleInputChange"
        @focus="handleFocus"
        @blur="handleBlur"
    />
    <label
        v-if="label"
        :for="inputId"
        :class="{ 'focused': isFocus || value || phoneNumber }"
    >
      {{ label }}
    </label>
    <span v-if="hasError && !isFocus" class="error-icon">
      <img :src="require('@/assets/exclamation.svg')" alt="alert">
    </span>
    <span v-if="hasError" class="error-text">
      {{ errorText }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'Tele2TelephoneInput',
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    error: {
      type: String,
    },
  },
  data() {
    return {
      inputId: `tele2-input-${Math.random().toString(36).substr(2, 9)}`,
      isFocus: false,
      phoneNumber: this.value || '',
      hasError: false,
      errorText: this.error || 'Поле не должно быть пустым',
    };
  },
  methods: {
    formatPhoneNumber() {
      const cleanedNumber = this.phoneNumber.replace(/\D/g, '');
      if (cleanedNumber.length <= 10) {
        this.phoneNumber = cleanedNumber.replace(
            /(\d{1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/,
            (_, part1, part2, part3, part4, part5) => {
              let formattedNumber = `+7`;
              if (part2) formattedNumber += ` ${part2}`;
              if (part3) formattedNumber += ` ${part3}`;
              if (part4) formattedNumber += `-${part4}`;
              if (part5) formattedNumber += `-${part5}`;
              return formattedNumber;
            }
        );
        this.$emit('input', this.phoneNumber);
      } else {
        this.phoneNumber = this.phoneNumber.slice(0, 16);
        this.$emit('input', this.phoneNumber);
      }
    },
    handleInputChange(event) {
      if (event.target.value.trim() === '') {
        this.hasError = true;
      } else {
        this.hasError = false;
      }

      this.formatPhoneNumber();
    },
    handleBlur() {
      this.isFocus = false;
      this.isValid();
    },
    handleFocus() {
      this.isFocus = true;
      if (!this.phoneNumber.startsWith("+7")) {
        this.phoneNumber = "+7" + this.phoneNumber;
      }
    },
    isValid() {
      if (!this.value) {
        this.hasError = true;
      } else if (this.value.trim().length < 16) {
        this.errorText = 'Минимальная длина номера 10 символов';
        this.hasError = true;
      } else {
        this.hasError = false;
      }
    }
  }
}
</script>
<style scoped>
.input-wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
}

.input-wrapper input {
  width: 100%;
  height: 60px;
  border: 1px solid #b9bdc4;
  border-radius: 6px;
  background-color: #fff;
  padding: 32px 50px 11px 14px;
  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 90px;
  outline: none;
}

.input-wrapper input:hover {
  border-color: #1f2229
}

.input-wrapper input.input-error {
  border-color: #d72f4b;
}

.input-wrapper label {
  position: absolute;
  top: 20px;
  left: 14px;
  color: #65707b;
  cursor: text;
  transition: transform 0.3s ease-in-out;
}

.input-wrapper label.focused {
  top: 7px;
  transform: scale(0.8) translate(-12.5%, 0);
}

.error-text {
  display: block;
  padding: 3px 0 2px;
  overflow: initial;
  color: #d72f4b;
  margin: 7px 4px 0 16px;
  font-size: 14px;
  line-height: 1.28;
  z-index: 1;
  width: auto;
}

.error-icon {
  position: absolute;
  top: 18px;
  right: 12px;
  display: inline-block;
  width: 26px;
  height: 26px;
}

</style>

<template>
  <div class="input-wrapper">
    <input
        :id="inputId"
        :value="value"
        :class="{
          'input-error': hasError && !isFocus,
          'disabled': disabled,
        }"
        :type="type"
        :disabled="disabled"
        @input="handleInputChange"
        @focus="handleFocus"
        @blur="handleBlur"
    />
    <label
        v-if="label"
        :for="inputId"
        :class="{
          'focused': isFocus || value,
          'disabled': disabled
        }"
    >
      {{ label }}
    </label>
    <span v-if="hasError && !isFocus" class="error-icon">
      <img :src="require('@/assets/exclamation.svg')" alt="alert">
    </span>
    <span v-if="hasError" class="error-text">
      {{ errorText }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'Tele2Input',
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    error: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
    }
  },
  computed: {
    errorText() {
      return this.error || 'Поле не должно быть пустым';
    }
  },
  data() {
    return {
      inputId: `tele2-input-${Math.random().toString(36).substr(2, 9)}`,
      isFocus: false,
      hasError: false,
    };
  },
  methods: {
    handleInputChange(event) {
      this.$emit('input', event.target.value);

      if (event.target.value.trim() === '') {
        this.hasError = true;
      } else {
        this.hasError = false;
      }
    },
    handleBlur() {
      this.isFocus = false;
      this.isValid();
      // if (!this.value) {
      //   this.hasError = true;
      // }
    },
    handleFocus() {
      this.isFocus = true;
    },
    isValid() {
      if (!this.value) {
        this.hasError = true;
      } else {
        this.hasError = false;
      }
    }
  }
}
</script>
<style scoped>
.input-wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
}

.input-wrapper input {
  width: 100%;
  height: 60px;
  border: 1px solid #b9bdc4;
  border-radius: 6px;
  background-color: #fff;
  padding: 32px 50px 11px 14px;
  transition: 350ms cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 90px;
  outline: none;
}

.input-wrapper input:hover {
  border-color: #1f2229
}

.input-wrapper input.input-error {
  border-color: #d72f4b;
}

.input-wrapper  input.disabled {
  border-color: #d1d8d6;
  color: rgba(143,147,153,0.5);
}

.input-wrapper label {
  position: absolute;
  top: 20px;
  left: 14px;
  color: #65707b;
  cursor: text;
  transition: transform 0.3s ease-in-out;
}

.input-wrapper label.focused {
  top: 7px;
  transform: scale(0.8) translate(-12.5%, 0);
}

.input-wrapper label.disabled {
  color: rgba(143,147,153,0.5);
}

.error-text {
  display: block;
  padding: 3px 0 2px;
  overflow: initial;
  color: #d72f4b;
  margin: 7px 4px 0 16px;
  font-size: 14px;
  line-height: 1.28;
  z-index: 1;
  width: auto;
}

.error-icon {
  position: absolute;
  top: 18px;
  right: 12px;
  display: inline-block;
  width: 26px;
  height: 26px;
}

</style>
